body {
  padding-bottom: 10rem;
}

img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  width: 100%;
  height: 12.7rem;
  overflow: hidden;
  z-index: -1;
  opacity: .5;
}

h1 {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  z-index: 10;
  margin: 0;
  font-weight: 900;
  text-align: left;
  text-transform: lowercase;
  user-select: none;
  position: relative;
}

.app-header {
  background-color: #e67e22;
  padding: .5rem 2rem 1rem;
}

.app-header h1 {
  font-size: 3rem;
  line-height: 2.8rem;
  color: #333;
  max-width: 40rem;
  margin: 1rem auto !important;
  color: #fff;
}

.app-header.home {
  background-color: transparent;
  max-width: 40rem;
  color: blue;
  max-width: 40rem;
  margin: 0rem auto !important;
  padding: .6rem 2rem 1.5rem 2rem !important;
}

.app-header.home h1 {
  font-size: 5rem;
  line-height: 4.3rem;
  color: #333;
}

.app-subheader {
  background-color: #d35400;
  color: white;
  padding: 1rem 2rem 1.2rem;
}

.app-subheader a {
  color: rgba(255, 255, 255, .6) !important;
  margin-top: -.1rem;
}

.app-subheader a:hover {
  color: #fff !important;
}

.breadcrumb {
  margin: 0 auto !important;
  text-align: left;
  max-width: 40rem;
  white-space: nowrap;
}

.breadcrumb * {
  display: inline-block;
  font-size: 1.2rem !important;
  margin-right: .6rem !important;
  font-weight: 800;
  vertical-align: top;
}

.breadcrumb > a > .icon {
  display: inline-block;
  margin-top: .2rem !important;
  margin-right: 0 !important;
}

.breadcrumb-description {
  white-space: normal;
  line-height: 1.6rem;
}

.platform-picker {
  position: absolute;
  width: calc(100vw);
  text-align: center;
  padding: 0 6vmin;
}

.purpose-picker {
  text-align: center;
}

.tag-line {
  font-size: 1.3rem;
  margin-bottom: 3rem;
}

.select-platform label {
  display: block;
  margin-bottom: 2rem !important;
  font-size: 1.2rem !important;
}

.select-platform-buttons {
  width: 100%;
  display: block;
  text-align: center;
}

.select-platform .button {
  display: block;
  margin: 2rem auto;
  min-width: 20rem;
  width: 60%;
}

.platform-selector,
.purpose-selector,
.content-simulator,
.favorite-list {
  position: relative;
  border-radius: .5rem !important;
  padding: 2rem 2rem 6rem 2rem !important;
  margin: 0 auto !important;
  max-width: 40rem;
}

.favorite-list {
  padding: 0 0 6rem 0 !important;
}

.button {
  border-radius: .5rem !important;
  text-align: left !important;
}

.subheader-description {
  opacity: .6;
  font-weight: 200;
}

.subheader-description-platform {
  text-transform: capitalize;
}

.subheader-description-platform .icon {
  margin: 0 !important;
}

.form-field {
  text-align: left;
  margin-bottom: 3rem !important;
  font-size: 1.4rem;
}

.form-field-description {
  opacity: .7;
  font-size: small !important;
  margin: 0;
}

.form-field-input-field input {
  border: 0 !important;
  border-radius: 0 !important;
  border-bottom: .15rem solid rgba(0,0,0,.1) !important;
  padding: 1rem 0 .4rem !important;
}

.form-field-input-field input:focus {
  border-bottom: .15rem solid rgba(0,0,0,1) !important;
}

.visualizer {
  border: 1px solid #ccc;
  text-align: left;
}

.visualizer.instagram {
  margin-bottom: 2rem;
}

.visualizer.instagram > .header {
  padding: 1rem;
  display: flex;
  opacity: .2;
}

.visualizer.instagram > .header > .avatar {
  width: 3rem;
  height: 3rem;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 1rem;
}

.visualizer.instagram > .header > .author-details {

}

.visualizer.instagram > .header > .author-details > .author-name {
  font-weight: 800;
}

.visualizer.instagram > .header > .author-details > .author-published-date {
  font-weight: 200;
  opacity: .6;
  margin-top: -.2rem;
}

.visualizer.instagram > .post-image {
  width: 100%;
  height: 20rem;
  background-color: rgba(0,0,0,.03);
}

.visualizer.instagram > .post-text {
  padding: 1rem;
}

.visualizer.instagram > .post-text > .post-author {
  font-weight: 800;
  display: inline-block;
  margin-right: .4rem;

}

.like-tracker {
  text-align: right;
  position: absolute;
  max-width: 40rem;
  margin-top: 2rem;
  padding-right: 3rem;
  display: block;
  top: 0;
  width: 100%;
}

.home .like-tracker {
  position: absolute;
  top: 0;
  margin: 0;
  padding: 0;
  line-height: 0;
  margin-top: 1rem;
}

.fav-copy-item {
  text-align: left;
  font-size: 1.2rem;
  padding: 2rem;
  border-bottom: 1px solid #eee;
  display: flex;
}

.fav-copy-item:hover {
  background-color: #eee;
}

.fav-category {
  font-size: 1rem;
  opacity: .6;
  margin-bottom: .5rem !important;
}

.fav-category .chevron {
  margin-top: .6rem;
  margin-left: .2rem;
  vertical-align: top;
  display: inline-block;
}

.fav-copy-details {
  width: 100%;
}

.fav-copy-action {
  margin-left: 2rem;
  margin-top: .4rem;
}

.time-ago {
  opacity: .6;
  font-size: 1rem;
  margin-top: .5rem;
}

.favorites .breadcrumb .breadcrumb-description {
  margin-left: 0.6rem;
  opacity: 1;
}

.favorites .breadcrumb .subheader-description {
  opacity: 1;
  font-weight: 400;
}

.template-token {
  display: inline-block;
  border-bottom: 1px dashed #e67e22;
  color: #e67e22;
  cursor: pointer;
}

.template-token:hover {
  background-color: rgba(230, 126, 34,.1);
}